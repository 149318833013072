import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent/BlockContent";
import Layout from "../components/Layout/Layout";
import { Heading1 } from "../components/Typography/Typography";
import styles from "./pagestyles/OmFlyt.module.scss";

const OmFlyt = ({ location }) => {
  const {
    sanityOmFlyt: { title, content },
  } = useStaticQuery(GET_OM_FLYT);
  return (
    <Layout location={location}>
      <div className={styles.omFlyt}>
        <Heading1>{title}</Heading1>
        {content && <BlockContent blocks={content} />}
      </div>
    </Layout>
  );
};

const GET_OM_FLYT = graphql`
  query {
    sanityOmFlyt(_id: { regex: "/omFlyt$/" }) {
      title
      content: _rawContent(resolveReferences: { maxDepth: 3 })
    }
  }
`;

export default OmFlyt;
